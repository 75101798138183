<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor !== 'white'"
      app flat dense
      class="px-sm shadow-sm"
    >
      <v-btn small text color="white" class="ml-0 mr-0" max-width="100" @click.prevent="$router.push('/').catch(()=>{})">
        <v-img :src="logo" height="40" contain></v-img>
      </v-btn>
      <v-btn small text color="primary" class="ml-1 mr-1" @click="toggleVerticalSidebarDrawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-progress-linear
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />
      <v-toolbar-title>{{ appBarTitle }}
      </v-toolbar-title>
      <span class="ml-2" v-if="loggedInUser != null">{{ loggedInUser.counselor.name }}({{loggedInUser.counselor.userId}})</span> / {{vs.renderCounselorRole(loggedInUser.counselor.role)}}
      <v-spacer />
      <sound-bar class="d-flex justify-end pt-4" />
      <v-btn small text color="primary" @click="rightSideBar = !rightSideBar">
        <v-badge color="warning" :content="centerMessages.length" overlap>
          <v-icon>mdi-android-messages</v-icon>
        </v-badge>
      </v-btn>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="rightSideBar"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <right-side-bar>
        <template v-slot:rightSideBarCloseButton>
          <v-btn icon color="primary" @click.stop="rightSideBar = !rightSideBar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </right-side-bar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import store from '@/store'
import vs from '@/utils/viewSupport'

export default {
  name: 'VerticalAppBar',
  components: {
    RightSideBar: () => import('./RightSideBar'),
    SoundBar: () => import('@/views/components/SoundBar')
  },
  computed: {
    ...mapGetters(['getThemeMode', 'appBarTitle', 'ws', 'centerMessages', 'loggedInUser'])
  },
  data() {
    return {
      vs,
      rightSideBar: false,
      logo: require('@/assets/images/ers-icon-center.png'),
    }
  },
  methods: {
    ...mapActions([
      'changeVerticalSidebarDrawer',
      'changeVerticalSidebarMini',
      'signOut'
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer()
    },
    logoutUser() {
      this.signOut()
      this.$router.push('/app/sessions/sign-in')
    }
  },
  mounted() {
    if (this.ws == null) {
      store.dispatch('connectWS')
    }
  }
}
</script>

<style lang="scss">
.tippy-box[data-theme~='light'] {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
    background: transparent !important;
    &__menu-options {
        margin-top: 3px !important;
        &__option {
            &__link {
                &:focus {
                    outline: none;
                    border: 1px solid none;
                }
                &:hover {
                    color: #0081ff;
                    .vnb__menu-options__option__arrow {
                        fill: #0081ff;
                    }
                }

                &__icon {
                    svg {
                        fill: #0081ff !important;
                    }
                }
            }
        }
    }

    &__sub-menu-options {
        &__option {
            &__link {
                &:focus {
                    outline: none;
                    border: 1px solid none;
                }
                color: #000 !important;
                &:hover {
                    color: #0081ff !important;
                }
            }
        }
    }
}

.vnb__collapse-button {
    &:focus {
        border: 1px solid none;
        outline: none;
    }
    &:after {
        content: '\F035C';
        font-size: 25px;
        font-weight: 600;
        font-family: 'Material Design Icons';
    }
    svg {
        display: none !important;
    }
}

.vnb__popup {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: scroll !important;
    .vnb__popup__top__close-button {
        &:focus {
            border: 1px solid none;
            outline: none;
        }
        top: 20px;
        right: -33px;
        svg {
            fill: #000 !important;
        }
    }
}
</style>
